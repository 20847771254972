const baseDomainNeed = "https://balak-setu.cdpi.dev/ssns"
const baseDomainFulfill = "https://balak-setu.cdpi.dev/ssfs"
const baseDomainVolunteering = "https://balak-setu.cdpi.dev/ssvs"
const baseDomainCounsellor ="https://balak-setu.cdpi.dev/acss"

// const baseDomainNeed = "http://localhost:9000"
// const baseDomainFulfill = "http://localhost:8090"
// const baseDomainVolunteering = "http://localhost:9090"
// const baseDomainCounsellor ="http://localhost:9019"


const configData = {
    "USER_GET" : `${baseDomainVolunteering}/api/v1/serve-volunteering/user/counsellor`,
     "USER" : `${baseDomainVolunteering}/api/v1/serve-volunteering/user`,
    "CHILD_CHAT_GET": `${baseDomainCounsellor}/api/v1/counsellor/search`,
    "SEND_MESSAGE" :`${baseDomainCounsellor}/api/v1/counsellor/save/message`,
    "MESSAGE_GET" :`${baseDomainCounsellor}/api/v1/counsellor/search/message`,
    // "MESSAGE_GET_RECEIVED" :`http://localhost:9019/api/v1/counsellor/search/message/received`,
    "NEED_GET": `${baseDomainNeed}/api/v1/serve-need/need`,
    "NEED_SEARCH": `${baseDomainFulfill}/api/v1/serve-fulfill/nomination`,
    "NEED_FULFILL":`${baseDomainFulfill}/api/v1/serve-fulfill/nomination`,
    "NEEDTYPE_GET": `${baseDomainNeed}/api/v1/serve-need/needtype`,
    "ENTITY_GET": `${baseDomainNeed}/api/v1/serve-need/entity`,
    "NEED_POST" : `${baseDomainNeed}/api/v1/serve-need/need/raise`,
    "NEED_BY_TYPE" : `${baseDomainNeed}/api/v1/need/serve-need/need-type/create`,
    "NOMINATED_USER_FETCH" : `${baseDomainVolunteering}/api/v1/serve-volunteering/user`,
    "NEED_BY_USER" : `${baseDomainNeed}/api/v1/serve-need/need/user`,
    "NOMINATION_CONFIRM" : `${baseDomainFulfill}/api/v1/serve-fulfill/nomination/{needId}/nominate`,
    "NEED_REQUIREMENT_GET": `${baseDomainNeed}/api/v1/serve-need/need-requirement`,
    "NOMINATIONS_GET": `${baseDomainFulfill}/api/v1/serve-fulfill/nomination`,
    "NEEDPLAN_GET": `${baseDomainNeed}/api/v1/serve-need/need-plan`,
    "NEEDPLAN_DELIVERABLES": `${baseDomainNeed}/api/v1/serve-need/need-deliverable`,
    "NEED_TYPE_GET_FROM_USER_ID":`${baseDomainNeed}/api/v1/serve-need/needtype/user`
}

module.exports = configData;