import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createFocusTrap } from "focus-trap";
import "./ChatWindow.css";
import ChatMessage from "../ChatMessage/ChatMessage";

const ChatWindow = ({
  isOpen,
  messages,
  receivedDateTime,
  onClose,
  onMessageSent,
  position,
  title,
  user
}) => {
  const chatWindow = useRef();
  const chatWindowBody = useRef();
  const userInput = useRef();

  const [message, setValue] = useState("");
  const [showMessage, setShowMessage] = useState("")
  const [focusTrap, setFocusTrap] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [isSent, setIsSent] = useState(false);
  const [dateTimeStamp, setDateTimeStamp] = useState("");
  const [isUser, setIsUser] = useState(false);

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handleSubmit = (date) => {
    setShowMessage(message)
    console.log(message);
    setDateTimeStamp(new Date())
    console.log(dateTimeStamp);
    onMessageSent({ message, originIpAddress: ipAddress, DateTime: "new" });
    setIsSent(true);
    setValue('');
    console.log(user);
    if (user != null) {
      console.log(user);
      setIsUser(true)
    }
  };

  const setChatWindowScrollPosition = () => {
    const _chatWindowBody = chatWindowBody.current;
    _chatWindowBody.scrollTop = _chatWindowBody.scrollHeight;
  };

  const autExpandInput = () => {
    const _userInput = userInput.current;
    _userInput.style.height = "auto";
    _userInput.style.height = `${_userInput.scrollHeight}px`;
  };

  // useEffect(() => {
  //   fetch("https://ipinfo.io/json")
  //     .then(res => res.json())
  //     .then(({ ip }) => setIpAddress(ip));

  //   setFocusTrap(
  //     createFocusTrap(chatWindow.current, {
  //       clickOutsideDeactivates: true,
  //       fallbackFocus: chatWindow.current
  //     })
  //   );
  // }, []);

  useEffect(() => {
    if (!focusTrap) return;

    if (isOpen) {
      focusTrap.activate();
    } else {
      focusTrap.deactivate();
    }
  }, [isOpen, focusTrap]);

  useEffect(() => {
    // console.log(messages);
    setChatWindowScrollPosition();
  }, [messages]);

  useEffect(() => {
    autExpandInput();
  }, [message]);


  const timeReturn =(time) =>{
    const timestamp = "2024-04-10T08:24:47.585+00:00";
    const date = new Date(time);

    // Convert to Indian time zone (IST: Indian Standard Time)
    const indianTime = date.toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
    return indianTime;
  }

  return (
    <div
      ref={chatWindow}
      className={classnames("chat-window", {
        "is-open": isOpen,
        [`chat-window--${position}`]: position
      })}
    >
      <div className="chat-window__header">
        <div className="chat-window__title">{title}</div>
        <button className="chat-window__close-btn" onClick={() => onClose()}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
          >
            <path
              d="M493.268,0H18.732C8.387,0,0,8.387,0,18.732v474.537C0,503.613,8.387,512,18.732,512h474.537
		c10.345,0,18.732-8.387,18.732-18.732V18.732C512,8.387,503.613,0,493.268,0z M358.763,332.273c7.315,7.314,7.315,19.175,0,26.49
		s-19.175,7.315-26.49,0L256,282.49l-76.273,76.273c-7.315,7.315-19.175,7.315-26.49,0c-7.315-7.314-7.315-19.175,0-26.49
		L229.51,256l-76.273-76.273c-7.315-7.314-7.315-19.175,0-26.49c7.314-7.314,19.175-7.314,26.49,0L256,229.51l76.273-76.273
		c7.314-7.314,19.175-7.314,26.49,0c7.315,7.314,7.315,19.175,0,26.49L282.49,256L358.763,332.273z"
            />
          </svg>
        </button>
      </div>
      <div ref={chatWindowBody} className="chat-window__body">

        {messages.map((message, index) => (
          <ChatMessage
            key={index}
            message={message.message }
            dateTimeStamp={timeReturn(message.msgTime)}
            isReceived={message.userType === "TF" ? true : false}
          />
        ))}
        {/* {
          (
            isSent &&
            <ChatMessage
              message={showMessage}
              isUser={isUser}
              isReceived={true}
              dateTimeStamp={new Date().toLocaleString()}
            />
          )
        } */}
      </div>
      <div className="chat-window__footer">
        <textarea
          ref={userInput}
          className="chat-window__input"
          rows="1"
          placeholder="Enter your message..."
          value={message}
          onChange={handleChange}
        />
        <button
          className="chat-window__send-btn"
          type="button"
          onClick={() => handleSubmit(new Date().toLocaleString())}
          disabled={!message}
        >
          Send
        </button>
      </div>
    </div>
  );
};

ChatWindow.propTypes = {
  isOpen: PropTypes.bool,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      originIpAddress: PropTypes.string,
      message: PropTypes.string,
      dateTimeStamp: PropTypes.string
    })
  ),
  onClose: PropTypes.func,
  onMessageSent: PropTypes.func,
  position: PropTypes.oneOf([
    "top-right",
    "top-left",
    "bottom-right",
    "bottom-left"
  ]),
  title: PropTypes.string
};

ChatWindow.defaultProps = {
  isOpen: false,
  messages: [],
  onClose() { },
  onMessageSent() { },
  position: "bottom-right",
  title: "Chat"
};

export default ChatWindow;