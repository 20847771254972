import React, { useState, useEffect } from 'react';
import './BlackPage.css';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import SimpleChatBot from '../Chatbot/SimpleChatBot.jsx';
import ChatWindow from '../ChatWindow/ChatWindow.js';
import ChatIcon from '@mui/icons-material/Chat';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ManIcon from '@mui/icons-material/Man';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { NotificationIcon } from 'react-autoql';




function BlackPage({ open, onClose, need }) {
  const userId = useSelector((state) => state.user.data.osid)
  const configData = require('../../configure.js');
  const [messages, setMessages] = useState([]);
  const [ntypeData, setNtypeData] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dateTime , setDateTime] = useState(null);
  const[childPhone,setChildPhone] = useState(null);
  const[receivedMessage , setReceivedMessage] = useState([]);
  const[listOfMockedPhoneNumber , setListOfMockedPhoneNumber] = useState([]);
  const [mockedNumber, setMockedNumber] = useState(null);
  

  useEffect(() => {
  
    const fetchData = async () => {
      try {
        console.log(userId);
        const userResponse = await axios.get(`${configData.USER_GET}/${userId}`);
        console.log(userId);
        console.log(userResponse.data.email);
        const response = await axios.get(`${configData.CHILD_CHAT_GET}?emailId=${userResponse.data.email}`);
        // console.log(userId);
        // console.log(response.data.listOfUser);
        // console.log(response.data.sizeOfReceivedMessages);
        setNtypeData(response.data.listOfUser);
        setReceivedMessage(response.data.sizeOfReceivedMessages)
        setListOfMockedPhoneNumber(response.data.listOfMockedPhoneNumber)
        
      } catch (error) {
        console.error('Error fetching ntypeData:', error);
      }


    };
    fetchData();
  }, []);

  const handleMessageSent = message => {
    // console.log(message.message);
    sendMEssage(message.message)
  };


   

  const sendMEssage = async (message ) => {
    const dataToPost = { "message" : message};

    try {
      // console.log(message);
      const response = await axios.post(`${configData.SEND_MESSAGE}?phoneNumber=${childPhone}`,dataToPost);

    } catch (error) {
      console.error('Error fetching ntypeData:', error);
    }


  };


  const handleClose = () => {
    setIsOpen(false);
    
  };
  const fetchMesseag = async (childPhoneNumber) => {
    try {
      
      // console.log(childPhoneNumber);
      const response = await axios.get(`${configData.MESSAGE_GET}?phoneNumber=${childPhoneNumber}&counsellorId=${userId}`);
      // console.log(response.data);
      setMessages(response.data);
      // console.log(response);
      setDateTime(response.data[2])
      setChildPhone(childPhoneNumber);
      
    } catch (error) {
      console.error('Error fetching ntypeData:', error);
    }
  };
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      if(childPhone !=null){
        fetchMesseag(childPhone);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
      
    };
  }, [isOpen]); 


  const handlePopupOpen = async(childPhoneNumber , mock) => {
   
    setChildPhone(childPhoneNumber);
    const response = await axios.get(`${configData.MESSAGE_GET}?phoneNumber=${childPhoneNumber}&counsellorId=${userId}`);
    if(response.data.length>0){
      setShowChat(true);
    }
    else {
      alert('No messages found for this phone number. You can only start a conversation after the child has initiated it.');
      return; 
    }
   
    setIsOpen(!isOpen);
    setMockedNumber('Anonymous '+mock)
    console.log("Chat window opens successfully!");
  };


  const getSize = (childPhoneNumber) => {
    // console.log(childPhoneNumber);
    // console.log(receivedMessage);
    // console.log(receivedMessage[childPhoneNumber])
    return receivedMessage[childPhoneNumber];
  };


  const getAbstractedNumber = (childPhoneNumber) => {
    
    return listOfMockedPhoneNumber[childPhoneNumber];
  };




  return (
    <div className={`need-popup ${open ? "open" : ""}`}>
      <div className="buttonNSClose"><button onClick={onClose}>X</button></div>
      <div className="wrapNeedPopup">

        <div className="contentNeedPopup">
          <div className="wrapAllNT">
            {ntypeData.map((item) => (
              <div key={item.name} className="gridItem" onClick={() => handlePopupOpen(item.phoneNumber,item.id )}>
                
                <div className="numNeedsNT">
                  <i class="fa fa-child" aria-hidden="true"></i>
                  <span>
                  <i><PhoneAndroidIcon style={{ height: "30px" }} /></i>
                    {'Anonymous '+item.id}

                  </span>
                  <br/>
                  <span>
                  <i><ManIcon style={{ height: "30px" }} /></i>
                 {item.gender}
                  </span>
                  <br/>
                  <i><StickyNote2Icon style={{ height: "30px" }} /></i>
                  <span>
                  Age {item.age}
                  </span>
                  <br/>
                  <i><QuestionAnswerIcon fontSize="large"  /></i>
                  <span>
                   {getSize(item.phoneNumber)}
                  </span>
                </div>
              </div>
            ))}

          </div>

        </div>
        {showChat && (
                  <div>
                    <ChatWindow
                      isOpen={isOpen}
                      messages={messages}
                      receivedDateTime={dateTime}
                      onClose={handleClose}
                      onMessageSent={handleMessageSent}
                      title= {"Chat with   "+mockedNumber}
                      user={userId}
                    />
                  </div>
                )}
      </div>
    </div>



  );
}
export default BlackPage;