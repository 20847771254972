import React, { useEffect, useState } from "react";
import "./Registration.css";
import {
  Autocomplete,
  Checkbox,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import axios from 'axios'
import RegFormSuccess from "../RegFormSuccess/RegFormSuccess.js";
import RegFormFailure from "../RegFormFailure/RegFormFailure.js";

const configData = require('../../configure.js');
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CounsellorRegistration = (props) => {
  //constants

  const specialization = [
    "General mental health and well-being",
    "Peer pressure",
    "Bullying",
    "Stress",
    "Studies / competition / exam pressure",
    "Family / parental problems",
    "Divorce in the family",
    "Death in the family",
    "Parental substance abuse",
    "Body image / eating disorders",
    "Teenage relationships (friends and romantic)",
    "Anxiety",
    "Wanting to run away",
    "Self Harm",
    "Depression",
    "Suicide (risk to life)"
  ];

  const groupOfChildren = [
    "Queer children",
    "Neurodivergent children",
    "Children with visual impairment",
    "Children with auditory impairment",
    "Children with any other physical impairment"
  ];

  const prefDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const prefTime = ["Morning", "Afternoon", "Evening", "Night"];

  const interests = [
    "Accounting",
    "Aerospace Engineering",
    "Animation",
    "Artificial Intelligence",
    "Biomedical Engineering",
    "Biotechnology Research",
    "Blockchain Development",
    "Brand Management",
    "Business Analysis",
    "Business Consulting",
    "Business Intelligence",
    "Chemical Engineering",
    "Civil Engineering",
    "Clinical Psychology",
    "Clinical Trials",
    "Content Writing",
    "Copywriting",
    "Corporate Training",
    "Criminal Justice",
    "Culinary Arts",
    "Data Analysis",
    "Data Engineering",
    "Data Science",
    "Dentistry",
    "Digital Advertising",
    "Digital Illustration",
    "Digital Marketing",
    "E-commerce Management",
    "Educational Technology",
    "Energy Management",
    "Environmental Engineering",
    "Environmental Law",
    "Environmental Policy",
    "Environmental Science",
    "Fashion Design",
    "Fashion Merchandising",
    "Financial Analysis",
    "Financial Planning",
    "Game Development",
    "Genetic Counseling",
    "Graphic Design",
    "Health Informatics",
    "Healthcare Administration",
    "Human Resources",
    "Humanitarian Aid",
    "Information Security",
    "International Relations",
    "Investment Banking",
    "Investor Relations",
    "IT Consulting",
    "Journalism",
    "Lawyer / Legal Services",
    "Logistics",
    "Market Analysis",
    "Market Research",
    "Marketing Research Analysis",
    "Mechanical Engineering",
    "Medical Research",
    "Mobile App Development",
    "Music Production",
    "Neuroscience",
    "Nursing",
    "Pharmaceutical Sales",
    "Physical Therapy",
    "Political Science",
    "Product Management",
    "Project Management",
    "Public Health",
    "Public Relations",
    "Public Speaking",
    "Real Estate",
    "Robotics Engineering",
    "Sales Management",
    "Social Media Management",
    "Social Work",
    "Software Development",
    "Speech Therapy",
    "Supply Chain Management",
    "Sustainable Design",
    "Technical Writing",
    "Telecommunications",
    "Teaching",
    "UX/UI Design",
    "Urban Planning",
    "Video Editing",
  ];

  const qualifications = [
    "No Formal Education",
    "Primary Education",
    "Secondary Education",
    "Vocational Qualification",
    "Bachelor's Degree",
    "Master's Degree",
    "Doctorate or Higher",
  ];

  const employmentStatus = ["Full Time", "Part Time", "Unemployed"];

  const skillLevel = [
    "Beginner",
    "Intermediate",
    "Average",
    "Expert",
    "Advanced",
  ];

  const chipStyle = {
    fontSize: "12px",
    height: "24px",
    padding: "0 8px",
  };

  const initFormData = {
    skills: [{ skillName: "", skillLevel: "" }],
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    nationality: "",
    mobileNumber: "",
    email: "",
    address: "",
    city: "",
    district: "",
    state: "",
    landmark: "",
    pincode: "",
    languages: [],
    prefDays: [],
    prefTime: [],
    interests: [],
    qualification: "",
    affiliation: "",
    empStatus: "",
    yoe: "",
    reference: "",
    consent: false,
  };

  const [formData, setFormData] = useState(initFormData);

  const [nav, setNav] = useState(0);

  const refArray = Array.from({ length: 6 }, () => React.createRef());

  const handleAutoCompleteChange = (name, value) => {
    handleChange({ target: { name: name, value: value } });
  };

  const handleChange = (event, count = 0) => {
    // console.log(event, "check this");
    const { name, value } = event.target;
    if (name === "skillName" || name === "skillLevel") {
      const updatedSkills = [...formData.skills];
      updatedSkills[count][name] = value;
      console.log("updated skills", updatedSkills);
      setFormData({
        ...formData,
        skills: updatedSkills,
      });
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addSkill = () => {
    const updatedSkills = [...formData.skills];
    updatedSkills.push({ skillName: "", skillLevel: "" });
    setFormData({
      ...formData,
      skills: updatedSkills,
    });
  };

  const removeSkill = (index) => {
    const updatedSkills = [...formData.skills];
    updatedSkills.splice(index, 1);
    setFormData({
      ...formData,
      skills: updatedSkills,
    });
  };

  const selfDeclare = ["yes"];
  const consent_ans = ["yes"];
  const consent = ["yes"];
  const validateFields = () => {
    for (const key in formData) {
      if (formData[key] === "" || formData[key] == []) {
        return false;
      }
    }

    for (const skill in formData.skills) {
      if (skill.skillLevel === "" || skill.skillName === "") {
        return false;
      }
    }

    return true;
  };

  // useEffect(() => {
  //   console.log(formData, "inside useEffect");
  // }, [formData]);

  const dataToPost = {
    "identityDetails": {
      "fullname": formData.firstName,
      "name": formData.lastName,
      "gender": formData.gender,
      "dob": formData.dob,
      "Nationality": formData.nationality
    },
    "contactDetails": {

      "mobile": formData.mobileNumber,
      "address": {
        "city": formData.city,
        "state": formData.state,
        "country": 'India'
      }
    },
    "agencyId": "123",
    "status": "Active",
    "email": formData.email,
    "role": [
      "Volunteer"
    ]
  }

  const [regStatus, setRegStatus] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const onsubmit = () => {
    // if (validateFields()) {
    //   window.alert("Form submitted");
    //   return;
    // }
    // window.alert("Please enter all the details");

    console.log(dataToPost)
    axios.post(`${configData.USER_GET}/`, dataToPost)
      .then(function (response) {
        console.log('user created sucessfully', response);
        setRegStatus('success');
      })
      .catch(function (error) {
        console.log(error);
        setRegStatus('failure');
      })
  };

  const onNavClick = (key) => {
    const currentRef = refArray[key];
    currentRef.current.scrollIntoView({
      behaviour: "smooth",
      block: "end",
      inline: "nearest",
    });
    setNav(key);
  };

  return (
    <div>
      {(!regStatus) &&
        (<div className="reg-main">
          <div className="title-container">
            <span className="title">Counsellor Registration</span>
            <div className="info-card">

            </div>
          </div>
          <hr className="seperator" />
          <div className="button-container">
            <span style={{ float: "left" }}>
              Fill all the details below and be a Counsellor
            </span>
            <div style={{ textAlign: "right" }}>
              <button
                type="button"
                className="clear-btn"
                onClick={() => setFormData(initFormData)}
              >
                Clear All
              </button>
              <button
                type="button"
                className="clear-btn register-btn"
                onClick={() => onsubmit()}
              >
                Register
              </button>
            </div>
          </div>
          <div className="regContainer">
            <div className="formContainer">
              <div className="form-section" id={0} ref={refArray[0]}>
                <span className="formCat">Personal Details</span>
                <hr className="form-line" />
                <div className="formEntries">
                  <div className="formElement">
                    <label>Full Name</label>
                    <br />
                    <input
                      className="form-input"
                      placeholder="Enter your Full name"
                      name="firstName"
                      value={formData.firstName ? formData.firstName : ""}
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="form-section" id={1} ref={refArray[1]}>
                <span className="formCat">Contact Details</span>
                <hr className="form-line" />
                <div className="formEntries">
                  <div className="formElement">
                    <label>Mobile Number</label>
                    <br />
                    <input
                      className="form-input"
                      placeholder="Add your mobile number"
                      name="mobileNumber"
                      value={formData.mobileNumber ? formData.mobileNumber : ""}
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
                <br/>
                <div className="formEntries">
                  <div className="formElement">
                    <label>E-mail ID</label>
                    <br />
                    <input
                      className="form-input"
                      placeholder="chandlerBing@gmail.com"
                      name="email"
                      value={formData.email ? formData.email : ""}
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
              </div>
              <br/>
              <div className="form-section" id={1} ref={refArray[1]}>
                <span className="formCat">More Details as Counsellor</span>
                <hr className="form-line" />
                <div className="formEntries">
                  {/* selfDeclare*/}
                  <div className="formElement">
                    <label>I have read the 'BBS Needs' mentioned at the top of this form and self-declare that I meet the requirements </label>
                    <br />
                    <Select
                      displayEmpty
                      renderValue={
                        formData.selfDeclare !== ""
                          ? undefined
                          : () => ""
                      }
                      style={{ height: "4vh", width: "100%", textAlign: "left" }}
                      name="selfDeclare"
                      value={formData.selfDeclare ? formData.selfDeclare : ""}
                      onChange={handleChange}
                    >
                      {selfDeclare.map((selfDeclare, index) => (
                        <MenuItem key={index + selfDeclare} value={selfDeclare}>
                          {selfDeclare}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <br/>
                <div className="formEntries">
                  <div className="formElement">
                    <label>Orgnisation (if any)</label>

                    <h10>If you are joining as part of an organisation</h10>
                    <br />
                    <input
                      className="form-input"
                      placeholder=""
                      name="organisation"
                      value={formData.organisation ? formData.organisation : ""}
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
                <br/>
                <div className="formEntries">
                  <div className="formElement">
                    <label>Network (if any)</label>

                    <h10>If you are joining through a network such as TISS iCALL</h10>
                    <br />
                    <input
                      className="form-input"
                      placeholder=""
                      name="network"
                      value={formData.network ? formData.network : ""}
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
                <br/>
                <div className="formEntries">
                  <div className="formElement">
                    <label>Please share a picture of yourself that you would like displayed to the children through the rails </label>
                    <br />
                    <input
                          type="file"
                          onChange={handleFileChange}
                          accept=".pdf,.jpg"
                        />
                  </div>
                </div>
                <br/>
                <div className="formEntries">
                  <div className="formElement">
                    <label>Please share one line about yourself that you would like displayed to the children through the rails </label>
                    <br />
                    <input
                      className="form-input"
                      placeholder="Hi , I am Alexa"
                      name="lineAboutYourSelf"
                      value={formData.lineAboutYourSelf ? formData.lineAboutYourSelf : ""}
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
                <br/>
                <div className="formEntries">
                  <div className="formElement">
                    <label>Please attach your CV (with contact details of one professional reference we can reach out to) </label>
                    <br />
                    <input
                          type="file"
                          onChange={handleFileChange}
                          accept=".pdf,.jpg"
                        />
                  </div>
                </div>
                <br/>
                <div className="formEntries">
                  <div className="formElement">
                    <label>Please attach a copy of your Masters degree </label>
                    <br />
                    <input
                          type="file"
                          onChange={handleFileChange}
                          accept=".pdf,.jpg"
                        />
                  </div>
                </div>
                <br/>
                <div className="formEntries">
                  <div className="formElement">
                    <label>Please tick your areas of specialisation (as many as applicable) </label>
                    <br />
                    <Autocomplete
                      multiple
                      id="checkboxes-tags"
                      options={specialization}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      ChipProps={{ style: chipStyle }}
                      value={formData.specialisation ? formData.specialisation : []}
                      onChange={(event, value) =>
                        handleAutoCompleteChange("specialisation", value)
                      }
                      size={"small"}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Choose your preferred specialisation"
                        />
                      )}
                    />
                  </div>
                </div>
                <br/>
                <div className="formEntries">
                  <div className="formElement">
                    <label>Please tick if you have also worked with any of these groups of children (as many as applicable) </label>
                    <br />
                    <Autocomplete
                      multiple
                      id="checkboxes-tags"
                      options={groupOfChildren}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      ChipProps={{ style: chipStyle }}
                      value={formData.groupOfChildren ? formData.groupOfChildren : []}
                      onChange={(event, value) =>
                        handleAutoCompleteChange("groupOfChildren", value)
                      }
                      size={"small"}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Choose your preferred groupOfChildren"
                        />
                      )}
                    />
                  </div>
                </div>
                <br/>
                <div className="formEntries">
                  <div className="formElement">
                    <label>If you have chosen any of the last 3 (self-harm, depression, suicide) please elaborate on your experience / qualification / background in dealing with such cases before. </label>
                    <br />
                    <input
                      className="form-input"
                      placeholder=""
                      name="background"
                      value={formData.background ? formData.background : ""}
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
              </div>
              <br/>
              <div className="formEntries">
                {/* consent_ans*/}
                <div className="formElement">
                  <label>I understand how BBS works, and what the roles, responsibilities and escalation mechanisms of Trusted Friends are  </label>
                  <br />
                  <Select
                    displayEmpty
                    renderValue={
                      formData.consent_ans !== ""
                        ? undefined
                        : () => ""
                    }
                    style={{ height: "4vh", width: "100%", textAlign: "left" }}
                    name="consent_ans"
                    value={formData.consent_ans ? formData.consent_ans : ""}
                    onChange={handleChange}
                  >
                    {consent_ans.map((consent_ans, index) => (
                      <MenuItem key={index + consent_ans} value={consent_ans}>
                        {consent_ans}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <br/>
              <div className="formEntries">
                {/* consent*/}
                <div className="formElement">
                  <label>I have read, understood, and accept the Trusted Friend Participation Terms to always work in the best interests of the child </label>
                      <br/>
                  <Select
                    displayEmpty
                    renderValue={
                      formData.consent !== ""
                        ? undefined
                        : () => ""
                    }
                    style={{ height: "4vh", width: "100%", textAlign: "left" }}
                    name="consent"
                    value={formData.consent ? formData.consent : ""}
                    onChange={handleChange}
                  >
                    {consent.map((consent, index) => (
                      <MenuItem key={index + consent} value={consent}>
                        {consent}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>)}

      {(regStatus === 'success') && <RegFormSuccess />}
      {(regStatus === 'failure') && <RegFormFailure />}



    </div>
  );
};

export default CounsellorRegistration;
