import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./ChatMessage.css";

const ChatMessage = ({ dateTimeStamp, message, isSameOrigin , isUser , isReceived}) => {
  return (
    <div
      className={classnames("chat-message", {
        "is-same-origin": isSameOrigin
      })}
    >
     <div className={isReceived?"chat-message__item__timestamp_user":"chat-message__item__timestamp"}>{dateTimeStamp}</div>
      <div className={isReceived ? "chat-message__item_user" : "chat-message__item"}>
        {/* <span className="chat-message__item__text">{message}</span> */}
        <span className={isReceived ? "chat-message__item__text":"chat-message__item__text_received"}>{message}</span>
      </div>
      
    </div>
  );
};

ChatMessage.propTypes = {
  message: PropTypes.string,
  fromMe: PropTypes.bool
};

ChatMessage.defaultProps = {
  message: "",
  fromMe: false
};

export default ChatMessage;
