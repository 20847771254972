import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth'
import {getFirestore}  from "@firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB9g-e5wFsPwpiO1czqahFuT05vmIVG0eI",
  authDomain: "bharat-bal-setu.firebaseapp.com",
  projectId: "bharat-bal-setu",
  storageBucket: "bharat-bal-setu.appspot.com",
  messagingSenderId: "313247421101",
  appId: "1:313247421101:web:6711f680eb391941c6ec24",
  measurementId: "G-X1K0EN094F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);  //initialize firebase app at server
const fireStore =getFirestore(app);


//make references to auth
const auth = getAuth(app);  //initialize authentication service
const gprovider = new GoogleAuthProvider();
const fprovider = new FacebookAuthProvider();


export { auth, gprovider, fprovider };

