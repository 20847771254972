import React from 'react';
import './LoginBanner.css';

const BannerLogin = ({volunteerStatus}) => {
    const handleVolunteerStatus = () => {
        volunteerStatus(true)
    }

    return(
        // Banner part in the login screen
        <div className='loginBanner row' >
            <div className='blurBanner'>
                <div className="bannerContent col-12 col-sm-10 offset-sm-1">                    <div className="headBanner">
                        Welcome to  Bharat Bal Setu (ABODH)  
                    </div>
                    <div className="textBanner">
                    Bharat Bal Setu is a Digital Public Infrastructure approach to addressing access to Counselling and Justice at scale in India. Its first focal point is children aged 9-18 across society. It’s aim is primarily two-fold: 

Empower children with direct, simplified agency and choice to access, engage and interact with current ecosystem resources 

Support the existing robust ecosystem by building rails that can help them work at national scale with less dependency on funding and human resources.                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerLogin