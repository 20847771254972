import React, { useState } from 'react'
import './RegFormSuccess.css'
import formSuccessImg from './Illustration_Success.jpg'
import { BrowserRouter, Route } from 'react-router-dom/cjs/react-router-dom.min'
import VolunteerNeedType from '../VolunteerNeedType/VolunteerNeedType'

const RegFormSuccess = () => {

  const [ regStatus, setRegStatus ] = useState('');

  const onsubmit = () => {
        setRegStatus('success');
    }


  return (
    <div className='successPage'>
      <div className='successImg'>
        <img className='successLogo' src={formSuccessImg} alt="successLogo" width="60%" />
        <div className='successComment1'>
            Registration Successful
        </div>
        <div className='successComment2'>
            Sit Back and Relax
        </div>
      </div>
      <div className='successMsg'>
        <div className='msgBody'>
          <p className='msg'>
            Hurray! You've successfully registered. 
          </p>
          
          
        </div>
        <button className='moreButton'>
            View more details
        </button>
        <button
            type="button"
            className="clear-btn register-btn"
            onClick={() => onsubmit()}
          >
            BAck
          </button>
      </div>
      {(regStatus === 'success') && <VolunteerNeedType />}
    </div>
  )
}

export default RegFormSuccess
