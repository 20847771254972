import React, { useState, useEffect } from 'react'
import './VolunteerNeedType.css'
import axios from 'axios'
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import VolunteerNeeds from '../VolunteerNeeds/VolunteerNeeds';
import ntypeImage01 from '../../assets/counselling.png'
import ntypeImage02 from '../../assets/support.png'
import { useSelector, useDispatch } from 'react-redux'

const configData = require('../../configure.js');

function VolunteerNeedType() {
  const [ntypeData,setNtypeData] = useState([])   //for storing need types
  const [sortedNTs,setSortedNTs] = useState([])   //after sorting need types
  const user = useSelector(state => state.user);
  //fetch need types
  useEffect(()=> {
    const fetchData = async () => {
      try {
        
        console.log(user.data.osid);
        if(user.data.osid !== 'undefined'){
          const response = await axios.get(`${configData.NEED_TYPE_GET_FROM_USER_ID}?userId=${user.data.osid}`);
          console.log(response);
        console.log(response.data);
        setNtypeData(response.data); 
        if(response.data.length == 0){
            const response1 = await axios.get(`${configData.NEEDTYPE_GET}/?page=0&size=100&status=Approved`);
            console.log(response1);
            setNtypeData(response1.data.content);
        }
        
        
      }
     } catch (error) {
        console.error('Error fetching ntypeData:', error);
      }
    };
    fetchData();
  },[user]);

  //sort the fetched need types
  const sortedList = [...ntypeData].sort((a,b)=>a.name.localeCompare(b.name));
  

  //filter the sorted need types
  const [searchQueryNT, setSearchQueryNT] = useState('')

  const handleSearchChange = (event) => {
    setSearchQueryNT(event.target.value)
  }
  const filteredNTs =  sortedList.filter(item => {
    return item.name.toLowerCase().includes(searchQueryNT.toLowerCase())
  })

  useEffect(()=> {
    setSortedNTs(filteredNTs)
  },[ntypeData, searchQueryNT]);

  const needList = useSelector((state) => state.need.data);

  const [sortRev, setSortRev] = useState('')
  const handleSort = (e) => {
    setSortRev(e.target.value);
    console.log(sortRev)
    setSortedNTs(sortedNTs.reverse())
  };

  const groupByFirstAlphabet = (data) => {
    const groupedData = {};
    data.forEach((item) => {
      const firstAlphabet = item.name[0].toUpperCase();
      if(!groupedData[firstAlphabet]){
        groupedData[firstAlphabet] = [];
      }
      groupedData[firstAlphabet].push(item);
    });
    return groupedData;
  };

  const groupedNTs = groupByFirstAlphabet(sortedNTs);
     
  const [ needsList,setNeedsList ] = useState(false)
  const [ selectedNeedTypeId, setSelectedNeedTypeId ] = useState(null)
  const [ nTypeName, setNTypeName ] = useState('')

  const handleNTClick = (typeId, typeName) => {
    setNeedsList(true)
    setSelectedNeedTypeId(typeId)
    setNTypeName(typeName)
  }

  const updateNeedList = (status) => {
    setNeedsList(status)
  }

  const [activeView, setActiveView] = useState('grid');
  const handleView = (tab) => {
    setActiveView(tab);
  }

  const imageMap = {
    'Trusted Friend': ntypeImage01,
    'Trusted Support System': ntypeImage02,
  }

  return (
    <div className="wrapVolunteer">
      {/* display need types (NTs) when needList is unset*/}
      {!needsList && (
      <div className="vNeedType">
        {/* List of need types in grid view */}
        <div className="wrapAllNT">
          {Object.entries(groupedNTs).map(
            ([firstAlphabet, groupedList]) => (
              <div key={firstAlphabet} className="wrapGrid">
             
                {groupedList.map((item) => ( 
                  <div key={item.name} className="gridItem" onClick={() => handleNTClick(item.id, item.name)}>
                    <div className="imgGridNT">
                      <img src={imageMap[item.name]} alt="SunBirdLogo" height="118px" />
                    </div>
                    {item.name}
                  
                  </div>
                ))}
              </div>
            )) 
          }
        </div>
      </div>    
      )}
    {needsList && <VolunteerNeeds needTypeId={selectedNeedTypeId} nTypeName={nTypeName} updateNeedList={updateNeedList} />}
    </div>
  )

}

export default VolunteerNeedType